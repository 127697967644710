/*
 * 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
 * */
export default function getMenus(userId) {
    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    const userInfo = sessionStorage.getItem("current-user")
        ? JSON.parse(sessionStorage.getItem("current-user"))
        : {};
    if (userInfo.isShowTsMenu === 1) {
        let data = [
            {
                key: "book",
                text: "订单管理",
                icon: "book",
                path: "/order-manager",
                order: 1,
            },
            {
                key: "money-collect",
                text: "提现管理",
                icon: "money-collect",
                path: "/withdrawal-management",
                order: 2,
            },
            {
                key: "user",
                text: "个人资料",
                icon: "user",
                path: "/user",
                order: 4,
            },
        ];
        if (userInfo.is_main === 1) {
            data.push(
                {
                    key: "organ",
                    text: "机构资料",
                    icon: "bank",
                    path: "/organ",
                    order: 3,
                },
            );
            if(userInfo.isPackageMenu){
                data.push({
                    key: "dropbox",
                    text: "套餐管理",
                    icon: "dropbox",
                    order: 5,
                },
                {
                    key: "list",
                    parentKey: "dropbox",
                    text: "套餐列表",
                    path: "/package-list",
                    order: 6,
                    icon: "unordered-list",
                },
                {
                    key: "message",
                    parentKey: "dropbox",
                    text: "审核列表",
                    path: "/package-review",
                    order: 7,
                    icon: "message",
                })
            }
        }
        return Promise.resolve(data);
    } else {
        return Promise.resolve([
            {
                key: "book",
                text: "订单管理",
                icon: "book",
                path: "/order-manager",
                order: 1,
            },
            {
                key: "user",
                text: "个人资料",
                icon: "user",
                path: "/user",
                order: 3,
            },
        ]);
    }
}
